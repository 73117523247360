import { CardNetwork } from '../types/enums';
import { DateTime } from 'luxon-business-days';
import { unsupportedConsumerStates } from '../components/common/form/StateDropdown';

/* Common Validation Rules to be used for form inputs */
export function validateEmail(email: string | null) {
    return !email || email.includes('@') || 'Email address must include an @ symbol.';
}

export function validateNullOrPhone(phone: string | null) {
    return !phone || validatePhone(phone);
}

export function validatePhone(phone: string) {    
    if (phone.length !== 10 && phone.length !== 0) {
        return 'Phone number must have 10 digits.';
    } else if (phone[0] === '0') {
        return 'Phone number cannot begin with 0.';
    }
    return true;
}

export function validateTaxId(taxId: any, intialTaxId: string, optional?: boolean) {    
    if(optional ){
        if(taxId && !/^[0-9]*$/.test(taxId)){
            return 'Tax Id mustbe numeric.';
        }
        else if ( taxId && taxId.length !== 9 && taxId.length !== 0) {
            return 'Tax Id must have 9 digits.';
        } 
    }
    else{
        if(!/^[0-9]*$/.test(taxId)){
            return 'Tax Id mustbe numeric.';
        }
        else if ( taxId !== intialTaxId  && taxId.length !== 9 && taxId.length !== 0) {
                  return 'Tax Id must have 9 digits.';
        } 
    }
    
    return true;
}
export function validateState(state: string){    
    if (unsupportedConsumerStates.includes(state)) {
        return 'Reliant cannot service consumers in CT, NJ, or WA. Contact RAM support for more information.';
     }
     else if (Number(state.length) === 0) {        
        return 'Please select a state.';
     }
     return true;
}

export const validateCreditCard = (
    cardNumber: string,
    expiry: string,
    cardNetwork: CardNetwork
): true | string => {
    // This credit card validation algorithm was taken and modified from:
    // https://learnersbucket.com/examples/javascript/credit-card-validation-in-javascript/

    interface cardValidationCharacteristics {
        cardNetwork: CardNetwork;
        length: string; // List of possible valid lengths of the card number for the card type
        prefixes: string; // List of possible card number prefixes for the card type
        checkDigit: true; // Whether a check digit exists
    }

    const permittedCards: cardValidationCharacteristics[] = [
        {
            cardNetwork: CardNetwork.Visa,
            length: '13,16',
            prefixes: '4',
            checkDigit: true
        },
        {
            cardNetwork: CardNetwork.MasterCard,
            length: '16',
            prefixes: '51,52,53,54,55',
            checkDigit: true
        },
        {
            cardNetwork: CardNetwork.Amex,
            length: '15',
            prefixes: '34,37',
            checkDigit: true
        },
        {
            cardNetwork: CardNetwork.Discover,
            length: '16',
            prefixes: '6011,622,64,65',
            checkDigit: true
        }
    ];

    // Establish card type
    let cardType: CardNetwork | undefined = permittedCards.find(
        c => c.cardNetwork === cardNetwork
    )?.cardNetwork;
    if (cardType === undefined) {
        return 'Unknown card type.';
    }

    // Ensure that the user has provided a credit card number
    if (cardNumber.length === 0) {
        return 'No card number provided.';
    }
    // Remove any spaces from the credit card number and check that it is numeric
    const cardNo = cardNumber.replace(/\s/g, '');
    const cardExpressionPattern = /^[0-9]{13,19}$/;
    if (!cardExpressionPattern.exec(cardNo)) {
        return 'Credit card number is in invalid format.';
    }

    // Now check the modulus 10 check digit - if required
    if (permittedCards[cardType].checkDigit) {
        let checksum = 0; // running checksum total
        let j = 1; // takes value of 1 or 2
        // Process each digit one by one starting at the right
        let calc;
        for (let i = cardNo.length - 1; i >= 0; i--) {
            // Extract the next digit and multiply by 1 or 2 on alternative digits.
            calc = Number(cardNo.charAt(i)) * j;
            // If the result is in two digits add 1 to the checksum total
            if (calc > 9) {
                checksum = checksum + 1;
                calc = calc - 10;
            }
            // Add the units element to the checksum total
            checksum = checksum + calc;
            // Switch the value of j
            if (j === 1) {
                j = 2;
            } else {
                j = 1;
            }
        }
        // All done - if checksum is divisible by 10, it is a valid modulus 10.
        if (checksum % 10 !== 0) {
            return 'Credit card number is invalid.';
        }
    }

    // The following are the card-specific checks we undertake.

    // Check for valid card number prefix
    const validPrefixes: string[] = permittedCards[cardType].prefixes.split(',');

    let prefixValid = false;
    const validatePrefix = (prefix: string) => {
        var expression = new RegExp('^' + prefix);
        if (expression.test(cardNo)) prefixValid = true;
    };
    validPrefixes.map(vp => validatePrefix(vp));
    // If it isn't a valid prefix there's no point at looking at the length
    if (!prefixValid) {
        return 'Credit card number is invalid.';
    }

    // Check that the card length is valid
    const validLengths: string[] = permittedCards[cardType].length.split(',');
    let lengthValid = !!validLengths.find(vl => vl === cardNumber.length.toString());
    if (!lengthValid) {
        return 'Credit card number has an inappropriate number of digits.';
    }

    // Check that expiry is valid
    const expiryExpression = /\d{4}/; // Expects "mmyy" (without a "/", which is to be rendered on the form only but not part of the form data)
    if (!expiryExpression.test(expiry)) {
        return 'The expiration date must be in the format mm/yy';
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() - 2000; // Convert 4 digit year (e.g. 2015) to 2 digit year (e.g. 15)
    const currentMonth = currentDate.getMonth() + 1; //0-11, add one
    const expiryYear = parseInt(expiry.substring(2));
    const expiryMonth = parseInt(expiry.substring(0, 2));
    if (currentYear > expiryYear || (currentYear === expiryYear && currentMonth > expiryMonth)) {
        return 'The card appears to be expired.';
    }

    // The credit card is in the required format.
    return true;
};

export function validateCurrency(value: number) {
    if (value !== parseFloat(value.toFixed(2))) {
        return 'Please enter a valid currency value.';
    }
    return true;
}

export function validateNonNegative(value: number) {
    return value >= 0 ? true : 'Please enter a positive value.';
}

export function validatePositive(value: number) {
    return value > 0 ? true : 'Please enter a value greater than zero.';
}

export function validateInteger(value: number) {
    return Number.isInteger(value) ? true : 'Please enter a whole number.';
}

export function validateDraftDate(date: luxon.DateTime) {
    if (date.startOf('day') >= DateTime.utc().plusBusiness().startOf('day')) {
        return true;
    } else {
        return 'Date must be at least one business day in the future.';
    }
}

const validateNullOr = (validator: (value: number) => string | boolean) => (value: number | null) =>
    value === null || value === undefined || validator(value);

export const validateNullOrCurrency = validateNullOr(validateCurrency);
export const validateNullOrInteger = validateNullOr(validateInteger);
export const validateNullOrGreaterThanZero = validateNullOr(validatePositive);
export const validateNullOrNonNegative = validateNullOr(validateNonNegative);

export function validateUsername(name: string) {
    //valid chars are: abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._@+
    if(name && !/^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789\-\.\_@\+]*$/.test(name))
    {
        return 'Username includes invalid characters.  A Username may only include the following: abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._@+';
    }
    return true;
}