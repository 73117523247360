import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DraftBulkEdit } from '../../../../types/drafts';
import { DraftMoveDirection } from '../../../../types/enums';
import { validateNonNegative, validateCurrency } from '../../../../utils/validationRules';
import WrappedInput from '../../../common/form/WrappedInput';

const DraftBulkEditFormInputs = (props: any) => {
    const { register, control, watch } = useFormContext<DraftBulkEdit>();
    const savingsAmountChanged = watch('savingsAmountChanged');
    const draftAmountChanged = watch('draftAmountChanged');
    const editfees = watch('editFees');
    const { fields } = useFieldArray({
        control,
        name: 'editFees'
    });

    return (
        <div className="consumer-input-section">
            <div>
            <div className={`flex edit-checkbox ${savingsAmountChanged ? 'hide' : ''}`}>
                    <WrappedInput<DraftBulkEdit>
                        inputName="draftAmount"
                        labelText="Total Draft Amount"
                    >
                        <input
                            type="number"
                            step='0.01'
                            readOnly={!draftAmountChanged}
                            {...register('draftAmount', {
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName="draftAmountChanged"
                        labelText="Edit"  
                        helpText={`Specify the dollar amount you want to send to the consumers savings for this draft. 
                        The total draft amount is derived from the Savings amount plus all Fee amounts plus the RAM Fee 
                        (for the first draft in each month).`}                      
                    >
                        <input
                            type="checkbox"
                            {...register(`draftAmountChanged`)}
                            disabled={savingsAmountChanged}
                        />
                    </WrappedInput>
                </div>
                <div className={`flex edit-checkbox ${draftAmountChanged ? 'hide' : ''}`}>
                    <WrappedInput<DraftBulkEdit>
                        inputName="savingsAmount"
                        labelText="Savings Amount"
                    >
                        <input
                            type="number"
                            readOnly={!savingsAmountChanged}
                            step='0.01'
                            {...register('savingsAmount', {
                                validate: {
                                    positive: validateNonNegative,
                                    currency: validateCurrency
                                },
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName="savingsAmountChanged"
                        labelText="Edit"  
                        helpText={`Specify the dollar amount you want to send to the consumers savings for this draft. 
                        The total draft amount is derived from the Savings amount plus all Fee amounts plus the RAM Fee 
                        (for the first draft in each month).`}                      
                    >
                        <input
                            type="checkbox"
                            {...register(`savingsAmountChanged`)}
                            disabled={draftAmountChanged}
                        />
                    </WrappedInput>
                </div>
                {fields.map((field, index) => (
                    <div className='flex edit-checkbox'><WrappedInput<DraftBulkEdit>
                        key={field.id}
                        inputName={`editFees[${index}].value`}
                        labelText={field.name}
                    >
                        <input
                            type="number"
                            step='0.01'
                            readOnly={!editfees[index].edit}
                            {...register(`editFees.${index}.value`, {
                                validate: {
                                    positive: validateNonNegative,
                                    currency: validateCurrency
                                },
                                valueAsNumber: true
                            })}
                            defaultValue={field.value}
                        />
                    </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName={`editFees[${index}].edit`}
                        labelText="Edit"   
                        helpText={`Specify the dollar amount for each fee for the checked drafts. 
                        The total draft amount is derived from the Savings amount plus all Fee amounts plus the RAM Fee 
                        (for the first draft in each month).`}                     
                    >
                     <input
                         type="checkbox"
                         {...register(`editFees.${index}.edit`)}
                         disabled={props.viewOnly}
                     />
                    </WrappedInput></div>
                ))}
            </div>
            <hr></hr>
            <div className="clear-fix">
                <input className="btn btn-primary float-left" disabled={props.viewOnly} type="submit" value="Save" />
            </div>
        </div>
    );
};

export default DraftBulkEditFormInputs;
