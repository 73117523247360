import * as React from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Edit,
    ExcelExport,
    GridComponent,
    Inject,
    PdfExport,
    Toolbar
} from '@syncfusion/ej2-react-grids';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatAsCurrency } from '../../../../utils/stringUtils';
import {
    formatDate,
    InternalValueAccessor,
    useCommonGridOptions,
    vaWrapper
} from '../../../../utils/gridUtils';
import { SimpleLedgerEntry } from '../../../../types/ledgers';
import { loadFromApi } from '../../../../api/baseApi';
import { useToasts } from 'react-toast-notifications';
import { getSimpleLedger } from '../../../../api/consumerApi';
import { nowAsServerDate } from '../../../../utils/dateUtils';
import { ConsumerContext } from '../ConsumerPage';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';

interface Props {}

interface BalancedLedger extends SimpleLedgerEntry {
    rowBalance: number;
}

const SimpleLedgersGrid = (props: Props) => {
    const [simpleLedgers, setSimpleLedgers] = useState<BalancedLedger[]>();
    const gridOptions = useCommonGridOptions('ConsumerLedgers');
    const ledgersGrid = gridOptions && gridOptions.ref;
    const consumer = useContext(ConsumerContext);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToasts();

    useEffect(() => {
        ledgersGrid.current?.refresh();
    }, [simpleLedgers, ledgersGrid]);

    const refreshGrid = useCallback(() => {
        const loadLedgers = async () => {
            const consumerPageApiWrapper = loadFromApi(
                error => error && addToast(error, { appearance: 'error', autoDismiss: true }),
                setLoading
            );

            const setLedgersWithBalance = (intermediateLedgers: SimpleLedgerEntry[]) => {
                // Set the balance for each row
                var balance = 0;
                var balancedLedgers = intermediateLedgers.map(f => {
                    balance += f.amount;
                    return {
                        ...f,
                        rowBalance: balance
                    };
                });
                setSimpleLedgers(balancedLedgers);
            };

            consumerPageApiWrapper(
                () => getSimpleLedger(consumer.consumerID, nowAsServerDate()),
                setLedgersWithBalance
            );
        };

        loadLedgers();
    }, [addToast, consumer.consumerID]);

    // Initial load
    useEffect(() => {
        refreshGrid();
    }, [refreshGrid]);

    /** Value Accessor functions **/
    //  used to manipulate or format raw data before displaying it in the grid
    const getRowNumber: InternalValueAccessor<BalancedLedger> = (_field, data) => {
        return data && simpleLedgers ? simpleLedgers.indexOf(data) + 1 : 0;
    };

    const getRowBalance: InternalValueAccessor<BalancedLedger> = (_field, data) => {
        return formatAsCurrency(data.rowBalance);
    };

    const getLedgerAmount: InternalValueAccessor<BalancedLedger> = (_field, ledgerData) => {
        return formatAsCurrency(ledgerData.amount);
    };

    const getPaymentDirection: InternalValueAccessor<BalancedLedger> = (_field, ledgerData) => {
        return ledgerData.paymentDirection === undefined
            ? ''
            : ledgerData.paymentDirection
            ? 'Credit'
            : 'Debit';
    };

    return (
        <div>
            {loading ? (
                <LoadingDisplay />
            ) : simpleLedgers ? (
                <GridComponent {...gridOptions} dataSource={simpleLedgers} width='1500px'>
                    <ColumnsDirective>
                        <ColumnDirective
                            valueAccessor={vaWrapper(getRowNumber)}
                            headerText="#"
                            width="7%"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field="scheduledDate"
                            //valueAccessor={vaWrapper(formatDate)}
                            headerText="Scheduled Payment Date"
                            width="20%"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field="notes"
                            headerText="Description"
                            width="20%"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field="action"
                            //valueAccessor={vaWrapper(getPaymentDirection)}
                            headerText="Type"
                            width="10%"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field="amount"
                            valueAccessor={vaWrapper(getLedgerAmount)}
                            headerText="Amount"
                            width="20%"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            valueAccessor={vaWrapper(getRowBalance)}
                            headerText="Trusted Account Balance"
                            width="20%"
                            textAlign="Left"
                            field="rowBalance"
                        />
                    </ColumnsDirective>
                    <Inject services={[Edit, CommandColumn, PdfExport, ExcelExport, Toolbar]} />
                </GridComponent>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default SimpleLedgersGrid;
