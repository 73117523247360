import { DateTime } from 'luxon-business-days';
import { Address } from '../types/common';
import { ConsumerContact } from '../types/consumer';
import { Schedule } from '../types/drafts';
import { DraftFrequency, PaymentType } from '../types/enums';
import { Creditor, Settlement } from '../types/settlements';
import { luxonDateToServerDate, nowAsServerDate } from './dateUtils';

export const defaultAddress: Address = {
    streetAddress: '',
    streetAddress2: null,
    state: '',
    city: '',
    zip: ''
};

export const defaultSettlement: Settlement = {
    id: 0,
    sentDate: nowAsServerDate(),
    clearedDate: nowAsServerDate(),
    ramFee: 0,
    sent: false,
    uidStatus: '',
    void: false,
    checkNumber: '',
    checkImageExtension: '',
    trackingNumber: '',
    description: '',
    paymentType: PaymentType.RamCheck,
    dueDate: luxonDateToServerDate(DateTime.utc().plus({ days: 3 })),
    CreditorAddressUid: 0,
    creditorName: '',
    referenceNumber: null,
    payeeName: '',
    payeeMailingDetails: null,
    payByPhoneDetails: null,
    bankAccount: null,
    bankName: null,
    bankAddress: { ...defaultAddress },

    consumerSsnLast4: '',
    consumerAccountNumber: '',
    memo: null,

    paymentAmount: 0,
    additionalFees: 0,
    totalOccurrences: 0,
    debtAmount: 0,
    upsStatus: '',
    location: '',
    signedForBy: ''
};

export const defaultCreditor: Creditor = {
    id: 0,
    name: '',
    address: { ...defaultAddress }
};

export const defaultConsumerContact: ConsumerContact = {
    firstName: '',
    lastName: '',
    middleInitial: null,
    address: { ...defaultAddress },
    taxId: '',
    birthDate: null,
    phone: '',
    fullName: ''
};

export const defaultSchedule: Schedule = {
    lastChanged: '',
    lastChangedUser: '',
    startDate: luxonDateToServerDate(DateTime.utc().plusBusiness({ days: 2 })),
    monthlyDraft: 0,
    totalAllowed: 0,
    feePercent: 0,
    occurrences: 0,
    draftFrequency: DraftFrequency.Monthly,
    fees: []
};
